import './landing-page.scss';
import { Header } from '../../components/header/header';
import t from '../../utils/i18n';
import devicesImage from '../../assets/devices.png';

export function LandingPage() {
    return (
        <div className="landing">
            <div className="header">
                {Header()}
            </div>

            <div className="hero">
                <div className="wrapper">
                    <div className="hero__text-panel">
                        <h3 className="title">{t('payoff1')}</h3>
                        <h4 className="subtitle">{t('payoff2')}</h4>
                    </div>
                    <div className="hero__image-panel">
                        <img className="devices" src={devicesImage} alt="Desktop and smartphone" />
                    </div>
                </div>
            </div>

            <div className="explaination">
                <div className="wrapper">
                    <h3 className="title">{t('explanation_title')}</h3>
                    <div className="panels">
                        <div className="panel">
                            <span className="material-icons">smartphone</span>
                            <h4 className="panel__title">{t('explaination_title1')}</h4>
                            <p className="panel__description">{t('explaination_description1')}</p>
                        </div>
                        <div className="panel">
                            <span className="material-icons">share</span>
                            <h4 className="panel__title">{t('explaination_title2')}</h4>
                            <p className="panel__description">{t('explaination_description2')}</p>
                        </div>
                        <div className="panel">
                            <span className="material-icons">lock</span>
                            <h4 className="panel__title">{t('explaination_title3')}</h4>
                            <p className="panel__description">{t('explaination_description3')}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="wrapper">
                    <div className="textWrapper">
                        An app by 
                        <a href="https://www.mrvanderpants.com" target="_blank" rel="noreferrer">
                            MRvanderPants
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

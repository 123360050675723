import './map.scss';
import React from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import { Icon, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import marker from '../../assets/Marker.png';
import { Request } from '../../utils/http';
import t from '../../utils/i18n';

export class MapComponent extends React.Component {

    state = {
        lastUpdatedTime: '',
        map: null,
        vectorLayer: null,
        iconFeature: null,
        interval: null
    };

    componentDidMount() {
        this.updateLocation((event) => {
            const interval = window.setInterval(() => {
                this.updateLocation((event2) => {
                    this.updateMarker(event2.longitude, event2.latitude);
                    this.setState({
                        lastUpdatedTime: event2.timestamp,
                    });
                });
            }, 10000);
            this.setState({
                lastUpdatedTime: event.timestamp,
                map: this.createMap(event.longitude, event.latitude),
                interval
            });
        });
    }

    render() {
        return (
            <div className="map-wrapper">
                <div id="map" className="map-container">
                    {this._invalidError()}
                </div>
                {this._lastUpdate()}
            </div>
        );
    }

    updateMarker(longitude, latitude) {
        const feature = this.state.map.getLayers().getArray()[1].getSource().getFeatures()[0];
        feature.setGeometry(new Point([longitude, latitude]));
        this.state.map.setView(new View({
            projection: 'EPSG:4326',
            center: [longitude, latitude],
            zoom: 18
        }));
    }

    updateLocation(callback) {
        const id = window.location.href.split('id=')[1];
        const url = `https://headedhomeapp.com/api/location/read.php?id=${id}`;
        Request(url, 'GET', (event) => {
            if (event == null) {
                clearInterval(this.state.interval);
                window.location.href = 'https://headedhomeapp.com';
                return;
            }
            callback(event);
        });
    }

    createMap(longitude, latitude) {
        const iconFeature = new Feature({
            geometry: new Point([longitude, latitude]),
            name: 'Current location',
        });

        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                scale: 0.05,
                src: marker,
            }),
        });
        iconFeature.setStyle(iconStyle);

        const vectorSource = new VectorSource({
            features: [iconFeature],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });
        this.setState({ vectorLayer, iconFeature });

        console.log('New map');
        return new Map({
            target: 'map',
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
                vectorLayer,
            ],
            view: new View({
                projection: 'EPSG:4326',
                center: [longitude, latitude],
                zoom: 18
            })
        });
    }

    _lastUpdate() {
        return (this.state.lastUpdatedTime === '')
            ? null
            : (
                <span className="last-update">
                    <span className="material-icons">schedule</span>
                    {this.state.lastUpdatedTime}
                </span>
            );
    }

    _invalidError() {
        return (this.state.lastUpdatedTime !== '')
            ? null
            : (
                <span className="error">{t('no_data_error')}</span>
            )
    }
}

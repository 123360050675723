import { MapPage } from './pages/map-page/map-page';
import { LandingPage } from './pages/landing-page/landing-page';
import './App.scss';
import './utils/i18n';

function App() {

  const page = (window.location.href.indexOf('id=') === -1)
    ? LandingPage()
    : MapPage();

  return (
    <div className="app">
      {page}
    </div>
  );
}

export default App;

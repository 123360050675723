export default function i18n(key) {
    
    const token = 'lang=';
    const url = window.location.href;
    const preferedLanguage = (url.indexOf(token) > -1)
        ? url.split(token)[1].substr(0, 2)
        : 'en';

    const translations = {
        en: {
            landing_description: 'Please download the Headed Home app from to Playstore to start using this website.',
            no_data_error: "This url is either incorrect or has been removed.",
            tagline: '...without worry',
            payoff1: 'Don\'t make people worry...',
            payoff2: 'Let your friends and family know where you\'re at with the Headed Home app.',
            explanation_title: 'How it works',
            explaination_title1: 'Start the app when you start traveling',
            explaination_title2: 'Share the url',
            explaination_title3: 'Stop the app once you\'ve arrived',
            explaination_description1: 'The app will automatically ping your location anonymously. The people you choose to share your location with will know it\'s you.',
            explaination_description2: 'This link will open the web-version of Headed Home, where people can track your progress on the map.',
            explaination_description3: 'We respect your privacy, and will delete your location immediately when you stop the app.',
        },
        nl: {
            landing_description: 'Download de Headed Home app van de Playstore om deze site te gebruiken.',
            no_data_error: "Deze url is incorrect of verwijderd.",
            tagline: 'Zonder zorgen thuis',
            payoff1: 'Zonder zorgen thuis',
            payoff2: 'Laat je vrienden en familie weten waar je bent met de Headed Home app.',
            explanation_title: 'Hoe het werkt',
            explaination_title1: 'Start de app wanneer je vertrekt',
            explaination_title2: 'Deel de url',
            explaination_title3: 'Stop de app wanneer je aankomt',
            explaination_description1: 'De app registreert jouw locatie automatisch en anoniem. De mensen waarmee je de link deelt weten natuurlijk al dat jij het bent.',
            explaination_description2: 'Deze link opent de web-versie van Headed Home, waar mensen je locatie op de kaart bij kunnen houden.',
            explaination_description3: 'We respecteren je privacy, en we zullen je locatie meteen verwijderen wanneer je de app stopt.',
        }
    };
    return translations[preferedLanguage][key];
};

import './map-page.scss';
import { Header } from '../../components/header/header';
import { MapComponent } from '../../components/map/map';

export function MapPage() {
    return (
        <div className="page">
            <div className="header">
                {Header()}
            </div>

            <div className="map">
                <MapComponent />
            </div>
        </div>
    );
}

import './header.scss';
import Logo from '../../assets/logo.png';
import AndroidDownload from '../../assets/download_android.png';
import t from '../../utils/i18n';

export function Header() {
    return (
        <div className="page__title-wrapper">
            <img src={Logo} alt="logo" className="page__logo" />
            <h1 className="page__title">
                <span className="page__title__first">Headed</span>
                <span className="page__title__second">
                    HOME
                    <span className="tagline">{t('tagline')}</span>
                </span>
            </h1>
            <a href="https://play.google.com/" rel="noreferrer" target="_blank">
                <img src={AndroidDownload} alt="Download on Google Playstore" className="download__android" />
            </a>
        </div>
    );
}

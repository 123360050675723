export function Request(url, type, callback) {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            callback(JSON.parse(this.responseText));
        } else if (this.status === 404) {
            callback(null);
        }
    };
    xmlhttp.open(type, url, true);
    xmlhttp.send();
}
